@import "variables";

body {
    font-family: arial; }
* {
    box-sizing: border-box; }

header {
    width: 200px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 2px solid #c1c1c1;

    ul {
        li {
            list-style: none;
            margin-bottom: 15px;
            a {
                color: black; } } } }

.main {
    padding-left: 220px;
    padding-top: 20px;
    padding-bottom: 40px; }


.admin-post {
    input[type="text"] {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 4px;
        border: 1px solid #c1c1c1;
        padding: 10px; }

    input[type="file"] {
        margin-top: 20px;
        margin-bottom: 20px; }

    button {
        height: 40px;
        padding: 10px;
        text-transform: uppercase;
        cursor: pointer;
        display: table;
        margin: 0 auto; }

    .files {
        .file {
            h3 {
                font-size: 14px;
                display: inline-block; }

            a {
                font-size: 14px;
                color: red; } } }

    textarea {
        width: 100%;
        height: 70px;
        resize: none;
        padding: 10px; }

    h2 {
        display: inline-block; }

    .delete {
        color: red;
        margin-top: 80px;
        float: right; } }

.admin-login {
    display: table;
    margin: 0 auto;
    margin-top: 150px;

    label {
        display: block; }

    input {
        height: 35px;
        border: 1px solid #c1c1c1;
        display: block;
        margin-bottom: 15px;
        padding-left: 5px; }
    span {
        display: block; }

    .invalid-feedback {
        color: red;
        font-size: 13px;
        margin-bottom: 10px; } }
